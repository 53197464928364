import React from "react";
import { Container, Row, Col } from "reactstrap";
import courseImg1 from "../../assests/images/science1.jpg";
import courseImg2 from "../../assests/images/math1.jpg";
import courseImg3 from "../../assests/images/english1.jpg";
import "./courses.css";
import CourseCard from "./CourseCard";
import FreeCourse from "../Free-course-section/FreeCourse";

const coursesData = [
  {
    id: "01",
    title: "Science",
    students: '8th, 9th, 10th',
    imgUrl: courseImg1,
  },

  {
    id: "02",
    title: "Mathematics",
    students: '8th, 9th, 10th',
    imgUrl: courseImg2,
  },

  {
    id: "03",
    title: "English",
    students: '8th, 9th, 10th',
    imgUrl: courseImg3,
  },
];

const Courses = () => {
  return (
    <>
      <Container>
        <Row>
          <Col lg="12" className="mb-5">
            <div className="course__top d-flex justify-content-between align-items-center">
              <div className="course__top__left">
                <h2>Our Popular Courses</h2>
                <p>
                  Embark on an exciting journey through our premier selection of courses, including science, mathematics, and English, meticulously designed for students from grades 8th to 10th. Ignite your passion for learning and soar to new heights with our dynamic and comprehensive curriculum."
                </p>
              </div>
            </div>
          </Col>
          {coursesData.map((item) => (
            <Col key={item.id} lg="4" md="6" sm="6">
              <CourseCard key={item.id} item={item} />
            </Col>
          ))}
        </Row>
      </Container>
      <FreeCourse />

    </>
    
  );
};

export default Courses;
