import React from "react";
import { Link } from "react-scroll";
import './courses.css';

const CourseCard = (props) => {
  const { id, imgUrl, title, lesson, students } = props.item;

  return (
    <div className="single__course__item" key={id}>
      <div className="course__img">
        <img src={imgUrl} alt="" className="w-100" />
      </div>

      <div className="course__details">
        <h6 className="course__title mb-4">{title}</h6>

        <div className=" d-flex justify-content-between align-items-center">
          <p className="lesson d-flex align-items-center gap-1">
            <i className="ri-book-open-line"></i> {lesson} Books
          </p>

          <p className="students d-flex align-items-center gap-1">
            <i className="ri-user-line"></i> {students}
          </p>
        </div>

        <div className=" d-flex justify-content-between align-items-center">
          

          <p className="enroll d-flex align-items-center gap-1">
            <Link
                      to="student-form-container"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      className="btn"
                    >
                      Enroll Now
                    </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
