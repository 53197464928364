import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./features.css";

const FeatureData = [
  {
    title: "Quick Learning",
    desc: "Discover the efficiency of Quick Learning! Dive into engaging content, master concepts swiftly, and propel your academic success with our innovative approach. Enroll now for accelerated growth and achievement!",
    icon: "ri-draft-line",
  },

  {
    title: "All Time Support",
    desc: "Experience unwavering assistance with our timeless support services, ensuring your needs are met across all moments. From past to present, count on us for reliable solutions tailored to stand the test of time.",
    icon: "ri-discuss-line",
  },

  {
    title: "Certification & Rewards",
    desc: "Unlock certifications and rewards with us! Elevate your learning experience and gain valuable credentials recognized by leading institutions. Explore our courses and embark journey towards success, where your efforts are duly rewarded.",
    icon: "ri-medal-line",
  },
];

const Features = () => {
  return (
    <>
      <Container className="container">
        <Row>
          {FeatureData.map((item, index) => (
            <Col lg="4" md="6" key={index}>
              <div className="divfeature">
              <div className="single__feature text-center px-4">
                <h2 className="mb-3">
                  <i className={item.icon}></i>
                </h2>
                <h6>{item.title}</h6>
                <p>{item.desc}</p>
              </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Features;
