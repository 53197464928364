import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-scroll"; // Import Link from react-scroll
import "./footer.css";

const footerQuickLinks = [
  {
    display: "Home",
    url: "heronav",
  },
  {
    display: "About",
    url: "aboutnav",
  },
  {
    display: "Courses",
    url: "course__top",
  },
  // {
  //   display: "Testimonials",
  //   url: "testimonial__wrapper",
  // },
  {
    display: "Contact Us",
    url: "student-form-container",
  },
];

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="5" md="3" className="mb-4 ">
            <h2 className=" d-flex align-items-center gap-1">
            <img src="./Untitled design-modified.png" style={{ width: '60px', height: '60px' }} alt="Luminarie Logo" /> Luminarie Institute
            </h2>

            <div className="follows">
              <p className="mb-0">Follow us on <span className="social_media_text">social media</span></p>
              <span className="wp_logo" >
               
                <a href="https://wa.me/918007443878?text=Hi,%0AMy%20name%20is%20%5BYour%20Name%5D,%0AI%20am%20from%20%5BSchool%20Name%5D,
                %0AI%20am%20in%20%5BStandard/Grade%5D,%0AMy%20Parent's%20mobile%20number%20is%20%5BParent Mobile No%5D,%0AAddress:%20%5BAddress%5D"target="_blank" rel="noreferrer">
                  <i className="ri-whatsapp-line  "></i>
                </a>
              </span>

              <span className="insta_logo">
              
                <a href="https://www.instagram.com/luminarie_institute?igsh=MWpsd3Y5bWkwdThpNQ==" target="_blank" rel="noreferrer">
                  <i className="ri-instagram-line"></i>
                </a>
              </span>

              <span className="fb_logo">
               
                <a href="https://www.facebook.com/profile.php?id=61556865629777" target="_blank" rel="noreferrer">
                  <i className="ri-facebook-line"></i>
                </a>
              </span>

              

              <span className="fb_logo">
              
                <a href="https://www.linkedin.com/in/luminarie-institute-4a77a62b5?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noreferrer">
                  <i className="ri-linkedin-line"></i>
                </a>
              </span>
            </div>
          </Col>

          <Col lg="3" md="4" className="mb-2">
            <h6 className="fw-bold">Explore</h6>
            <ListGroup className="link__list">
              {footerQuickLinks.map((item, index) => (
                <ListGroupItem key={index} className="border-0 ps-0 link__item">
                  {" "}
                  <Link
                    to={item.url}
                    spy={true}  
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="nav__link"
                  >
                    {item.display}
                  </Link>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
          
          <Col lg="4" md="6">
            <h6 className="fw-bold">Get in Touch</h6>

            <p>Address: Nashik, Maharashtra, India</p>
            <p> Phone: +91 80074 43878 </p>
            <p>Email: <span className="email_text">info@luminarieinstitute.com</span></p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
