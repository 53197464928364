import React from "react";
import "./about.css";
import { Container, Row, Col } from "reactstrap";
import aboutImg from "../../assests/images/about-us.jpg";

import "./about.css";

const AboutUs = () => {
  return (
    <section className="aboutnav">
      <Container>
        <Row>
          <Col lg="6" md="6">
          <h2 className="about">About Us</h2>
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__content">
              {/* <h2>About Us</h2> */}
              <p>
                <br/>
                <br/>
               We are a team of passionate and dedicated educators committed to shaping the next generation of leaders. With a focus on innovation and excellence, we provide a dynamic learning environment tailored to each student's unique needs. From interactive lessons to hands-on activities, we strive to inspire a lifelong love of learning. Join us on this journey as we cultivate curious minds and unleash the potential within every student.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      
    </section>
  );
};

export default AboutUs;
