import "./StudentEnquiryForm.css";

const StudentEnquiryForm = () => {
  return (
 <div className="student-form-container">
        <h2 className="form-heading">Student Information</h2>
        <form method="POST" action="https://formspree.io/f/xayrjnnp" id="student-form">
            <div className="form-group">
                <label htmlFor="fullName">Full Name:</label>
                <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    placeholder="Full Name"
                    required
                    autoComplete="name"
                />
            </div>
            <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="xyz@gmail.com"
                    autoComplete="email"
                />
            </div>
            <div className="form-group flex">
                <div>
                    <label htmlFor="schoolName">School Name:</label>
                    <input
                        type="text"
                        id="schoolName"
                        name="schoolName"
                        placeholder="School Name"
                        required
                        autoComplete="organization"
                    />
                </div>
                <div>
                    <label htmlFor="standard">Standard:</label>
                    <input
                        type="text"
                        id="standard"
                        name="standard"
                        placeholder="Standard"
                        required
                        autoComplete="off"
                    />
                </div>
            </div>
            <div className="form-group flex">
                <div>
                    <label htmlFor="phoneNo">(WhatsApp) Mobile No:</label>
                    <input
                        type="tel"
                        id="phoneNo"
                        name="phoneNo"
                        placeholder="Phone No"
                        pattern="[0-9]{10}"
                        title="Please enter a 10-digit phone number"
                        required
                        autoComplete="tel"
                    />
                </div>
                <div>
                    <label htmlFor="parentMobileNo">Parent Mobile No:</label>
                    <input
                        type="tel"
                        id="parentMobileNo"
                        name="parentMobileNo"
                        placeholder="Parent Mobile No"
                        pattern="[0-9]{10}"
                        title="Please enter a 10-digit phone number"
                        required
                        autoComplete="tel"
                    />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="address">Address:</label>
                <textarea
                    id="address"
                    name="address"
                    placeholder="Address"
                    required
                    autoComplete="street-address"
                ></textarea>
            </div>
            <button type="submit">Submit</button>
        </form>
    </div>
  )
    };

    
export default StudentEnquiryForm; 