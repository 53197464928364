import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./choose-us.css";
import videofile from "../Choose-us/student_video.mp4";
const ChooseUs = () => {
  return (
    <>
      <Container >
        <Row>
          <Col lg="6" md="6">
            <div className="choose__content">
              <h2>Why Choose Us</h2>
              <p>
                <strong>Looking for the perfect way to make the most of your summer vacation while staying ahead in your studies?</strong> Look no further! Our online classes for students in grades <strong>8th, 9th, and 10th</strong> offer a unique blend of quality education, interactive learning experiences, and flexibility to suit your summer schedule.
              </p>
              <h5>We are Offering!!</h5>
              <ul>
                <li>Expert Educators</li>
                <li>Interactive Learning</li>
                <li>All time personal Student Support</li>
                <li>Daily Test Series</li>
                <li>Weekly Doubt Session</li>
                <li>Weekly Parents Meet</li>
                <li>Comprehensive Curriculum</li>
                <li>Flexible Schedule</li>
              </ul>
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="choose__img">
            <video width="100%" height="350px" autoPlay loop muted>
              <source src={videofile} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            </div>
          </Col>
        </Row>
      </Container>
      
    </>
    
  );
};

export default ChooseUs;
