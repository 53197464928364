import React from "react";
import '../src/app.css';
import Header from "./components/Header/Header";
import HeroSection from "./components/Hero-Section/HeroSection";
// import AboutUs from "./components/About-us/AboutUs";
// import Courses from "./components/Courses-section/Courses";
// // import Testimonials from "./components/Testimonial/Testimonials";
// import StudentEnquiryForm from "./components/Contact/contact";
// import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div>
             <span>
                {" "}
                <a href="https://wa.me/918007443878?text=Hi,%0AMy%20name%20is%20%5BYour%20Name%5D,%0AI%20am%20from%20%5BSchool%20Name%5D,
                %0AI%20am%20in%20%5BStandard/Grade%5D,%0AMy%20Parent's%20mobile%20number%20is%20%5BParent Mobile No%5D,%0AAddress:%20%5BAddress%5D"
                target="_blank" rel="noreferrer">
                  <img src="./WhatsApp.png" alt="WhatsApp" className="whatsapp-icon" />
                </a>
              </span>
              <span>
                {" "}
                <a href="https://www.instagram.com/luminarie_institute?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                target="_blank" rel="noreferrer">
                  <img src="./Instagram.png" alt="Instagram" className="instagram-icon" />
                </a>
              </span>
      <Header />
      <HeroSection  />
      
      {/* <Courses />
      {/* <Testimonials /> */}
      {/* <StudentEnquiryForm />
      <Footer /> */} 
    </div>
  );
}

export default App;
