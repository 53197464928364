import React from "react";
import { Container, Row, Col } from "reactstrap";
import heroImg from "../../assests/images/hero-img1.jpg";
import "./hero-section.css";
import AboutUs from "../About-us/AboutUs";
import StudentEnquiryForm from "../Contact/contact";
import Footer from "../Footer/Footer";
import Courses from "../Courses-section/Courses";
import ChooseUs from "../Choose-us/ChooseUs";

const HeroSection = () => {
  return (
    <>
      <Container className="heronav">
        <Row>
          <Col lg="6" md="6">
            <div className="hero__content">
              <h1 className="mb-4 hero__title">
                Elevate Your <br/> Education with <br /><span className="highlight">Luminarie Institute
                </span>
                
              </h1> 
              <p className="mb-5">
                Welcome to Our Online Learning Platform! With our diverse range of courses for grades <strong>8th, 9th, 10th</strong> during the <strong>Summer vacation</strong>, you can embark on an enriching educational journey from the comfort of your home. 
              </p>
            </div>
            <div className="search">
                  <button className="btn">
                      <a
                        href="https://forms.gle/j2mwBASgQWx62axq7"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn"
                      >
                        Join Now
                      </a>
                    </button>

                </div>

          </Col>

          <Col lg="6" md="6">
            <img src={heroImg} alt="" className="w-100 hero__img" />
          </Col> 
        </Row>
      </Container>
      
      <AboutUs  />
      <ChooseUs />
      <Courses />
      
      
      {/* <Testimonials /> */}
      <StudentEnquiryForm />
      <Footer />
    </>
  );
};

export default HeroSection;
