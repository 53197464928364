import React, { useRef } from "react";
import { Container } from "reactstrap";
import { Link } from "react-scroll"; // Import Link from react-scroll
import "./header.css";

const navLinks = [
  {
    display: "Home",
    url: "heronav",
  },
  {
    display: "About",
    url: "aboutnav",
  },
  {
    display: "Courses",
    url: "course__top",
  },
  // {
  //   display: "Testimonials",
  //   url: "testimonial__wrapper",
  // },
  {
    display: "Contact Us",
    url: "student-form-container",
  },
];

const Header = () => {
  const menuRef = useRef();

  const menuToggle = () => menuRef.current.classList.toggle("active__menu");

  return (
    <header className="header">
      <Container>
        <div className="navigation d-flex align-items-center justify-content-between">
        <div className="d-flex flex-column bg-red">
  <div className="logo d-flex align-items-center gap-0">
    <img  src="./Untitled design-modified.png" alt="Luminarie Logo" style={{ width: '60px', height: '60px' }} />
    <h2 className="m-0"><span className="style_word">L</span>uminarie <span className="style_word ">I</span>nstitute</h2>
  </div>
  <div className="learning_div">Learning to Fly 🦅</div>
</div>



          <div className="nav d-flex align-items-center gap-5">
            <div className="nav__menu" ref={menuRef} onClick={menuToggle}>
              <ul className="nav__list">
                {navLinks.map((item, index) => (
                  <li key={index} className="nav__item">
                    <Link
                      to={item.url}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={250}
                      className="nav__link"
                    >
                      {item.display}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="nav__right">
              <p className="mb-0 d-flex align-items-center gap-2">
                <i className="ri-phone-line"></i> +91 8007443878
              </p>
            </div>
          </div>

          <div className="mobile__menu">
            <span>
              <i className="ri-menu-line" onClick={menuToggle}></i>
            </span>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
